import BoloAppList from '../objects/BoloAppList';
import SocialsBar from '../objects/SocialsBar';
import Title from '../objects/Title';

function App() {

  let apps = [

    {
      title: "Pathless",
      desc: "An arcade, customizable, loadout-based PVP game for Minecraft: Java Edition",
      link: "pathless"
    },
    {
      title: "Jabber",
      desc: "A fully homemade forum site, start talking here.",
      link: "https://jabber.bolious.com"
    },
    {
      title: "CubeEngine 2",
      desc: "A low level JS game engine for 2d Canvas games. Play the game I made with it here.",
      link: "https://ce2.bolious.com"
    },
    {
      title: "DV-ID (Deprecated)",
      desc: "A searchable database of over 4000 Students and Staff at Deleware Valley School District",
      link: "https://dvid.bolious.com"
    }
  
  ];

  return (
    <>
      <Title text={"Hi, I'm Bolo."}/>
      <SocialsBar />

      <h2>Projects</h2>
      <BoloAppList apps={apps} />

    </>
  );
}

export default App;
