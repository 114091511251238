import BoloApp from "./BoloApp";

function BoloAppList({apps})
{

    const list = apps.map((app,key) => <BoloApp info={app} key={key} />);

    return (
        <div className="bolo-app-list">
            {list}
        </div>
    );

}

export default BoloAppList;