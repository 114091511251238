import playButton from "../images/play.svg";

function BoloApp({info, children}){
    return (
        <div className="app">
            <h2 className="app-title">{info.title}</h2>
            <p className="app-description">{info.desc}</p>

            {children}
            
            {children ? <br /> : null}

            {info.link ? <a href={info.link} className="app-link">
            <img src={playButton} alt="Play button" className="play-button"/>
            </a> : null}

        </div>
    );
}

export default BoloApp;