import youtubeIcon from "../images/youtube.svg";
import twitterIcon from "../images/twitter.svg";
import discordIcon from "../images/discord.svg";
import githubIcon from "../images/github.svg";

function SocialsBar() {
  return (
    <div className="socials-bar">
        <a href="https://www.youtube.com/@bolo374" className="socials-link">
            <img src={youtubeIcon} alt="YouTube" className="socials-icon"/>
        </a>
        <a href="https://twitter.com/bolo374" className="socials-link">
            <img src={twitterIcon} alt="Twitter" className="socials-icon"/>
        </a>
        <a href="https://discord.com/users/452527519749373972" className="socials-link">
            <img src={discordIcon} alt="Discord" className="socials-icon"/>
        </a>
        <a href="https://github.com/bolo374" className="socials-link">
            <img src={githubIcon} alt="GitHub" className="socials-icon"/>
        </a>
    </div>
  );
}

export default SocialsBar;