import { useState } from 'react';

function Title({text})
{

    const finalTitle = text;
    const [title, setTitle] = useState("");
    const [typed, setTyped] = useState(0); //number of letters typed so far
    const interval = 150; //interval in ms

    if (typed < finalTitle.length)
    {
        //type out the title
        setTimeout(() => {

                setTitle(finalTitle.substring(0, typed + 1));
                setTyped(typed + 1);

        }, interval);
    }

    return (
        <h1 className='title'>{title}</h1>
    );
}

export default Title;