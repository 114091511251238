import BackButton from "../objects/BackButton";
import BoloApp from "../objects/BoloApp";
import Title from "../objects/Title";
import discordIcon from "../images/discord.svg";

function Pathless() {
  return <>

    <BackButton />

    <Title text={"Bolo's Pathless"} />
    <p>Pathless: Descendants is a fast paced, arcade style, spell based PVP game built for the newer versions of Minecraft: Java Edition.</p>

    <div className="socials-bar">
      <a href="https://discord.gg/6VsfQdnRsB"><img src={discordIcon} alt="Join the Discord"></img></a>
    </div>
    

    <BoloApp info={{
      title: "Overview",
      desc: `
      With dozens of unique spells, and 10+ custom maps, Pathless Descendants is the most polished experience. 
      It's been maintained and updated by me since 2022, and built almost entirely with a datapack. This is the best way to experience Pathless.
      `,
      link: ""
    }}>

        <a href="https://youtu.be/w927ZtK-Pz4">Watch the Trailer</a>
    </BoloApp>

  </>;
}

export default Pathless;